import Card, { PopupCardHeader } from './Card'

export function DeleteTemplatePopup({ handleClose, onDelete }: { handleClose: () => void; onDelete: () => void }) {
  return (
    <Card className="w-[20rem]">
      <PopupCardHeader handleClose={handleClose}>Sei sicuro?</PopupCardHeader>

      <p className="my-3">Stai per eliminare questo modello</p>

      <div className="flex justify-center">
        <button className="btn btn-secondary mr-2" onClick={handleClose}>
          Annulla
        </button>
        <button className="btn btn-danger" onClick={onDelete}>
          Elimina
        </button>
      </div>
    </Card>
  )
}
