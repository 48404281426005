import { useFormikContext } from 'formik'

import Spinner from './Spinner'

export default function SubmitButton({
  className = 'btn-primary',
  children
}: {
  className?: string
  children: React.ReactNode
}): JSX.Element {
  const { isSubmitting } = useFormikContext()

  return (
    <button type="submit" className={className}>
      {isSubmitting && <Spinner className="mr-2" />}
      {children}
    </button>
  )
}
