import { Template } from '@apb/database/schema'
import { Field, Form, Formik } from 'formik'
import ErrorField from './ErrorField'
import SubmitButton from './SubmitButton'

// Form per la modifica dei metadati del template

export function TemplateMetaForm({
  values,
  onSubmit,
  onCancel,
  operation
}: {
  values: Pick<Template, 'name' | 'description'>
  onSubmit: (values: Pick<Template, 'name' | 'description'>) => void
  onCancel?: () => void
  operation: 'CREATE' | 'EDIT'
}) {
  return (
    <Formik
      initialValues={values}
      onSubmit={onSubmit}
      validate={data => {
        if (data.name.trim() === '') return { name: 'Il nome non può essere vuoto' }

        return {}
      }}
    >
      <Form className="w-full">
        <ErrorField name="name" type="text" className="block w-full" placeholder="Nome del modello..." />
        <Field
          name="description"
          as="textarea"
          className="mb-3 block w-full"
          placeholder="Descrizione del modello..."
        />
        <div className="text-center">
          {onCancel && (
            <button
              className="btn btn-text mr-2"
              onClick={() => {
                onCancel()
              }}
            >
              Annulla
            </button>
          )}
          <SubmitButton className="btn btn-primary">{operation === 'CREATE' ? 'Crea' : 'Modifica'}</SubmitButton>
        </div>
      </Form>
    </Formik>
  )
}
