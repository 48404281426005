import Card from './Card'
import { TemplateMetaForm } from './TemplateMetaForm'

export function EditTemplatePopup({ values, onSubmit, handleClose }) {
  return (
    <Card className="w-[20rem]">
      <h3>Modifica Modello</h3>

      <TemplateMetaForm
        operation="EDIT"
        values={values}
        onSubmit={values => {
          onSubmit(values)
          handleClose()
        }}
      />
    </Card>
  )
}
