import { Template } from '@apb/database/schema'
import classNames from 'classnames'
import moment from 'moment'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import useOpenPopup from 'use-open-popup'

import Card from '../components/Card'
import { EditTemplatePopup } from '../components/EditTemplatePopup'
import { DeleteTemplatePopup } from '../components/DeleteTemplatePopup'
import { trpc } from '../trpc'
import EllipsisMenu from './EllipsisMenu'

function TemplateOptions({
  template,
  refresh
}: {
  template: Pick<Template, 'id' | 'name' | 'description'>
  refresh: () => void
}) {
  const { mutate: mutateUpdate } = trpc.templates.update.useMutation({
    onSettled: () => {
      refresh()
    }
  })
  const { mutate: mutateDelete } = trpc.templates.delete.useMutation({
    onSettled: () => {
      refresh()
    }
  })

  const editPopup = useOpenPopup(EditTemplatePopup)
  const deletePopup = useOpenPopup<{ handleClose: () => void; templateId: number }>(
    useCallback(
      ({ handleClose, templateId }) => (
        <DeleteTemplatePopup
          handleClose={() => {
            handleClose()
          }}
          onDelete={() => {
            mutateDelete({ id: templateId })
            handleClose()
          }}
        />
      ),
      [mutateDelete]
    )
  )

  return (
    <EllipsisMenu>
      <Card className="!rounded-lg !p-1 flex flex-col whitespace-nowrap">
        <button
          className="w-full transition hover:bg-gray-100 text-gray-500 hover:text-gray-900 text-left"
          onClick={() => {
            editPopup({ values: template, onSubmit: mutateUpdate })
          }}
        >
          <i className="fas fa-edit w-4 mr-2" />
          Modifica
        </button>
        <button
          className="w-full transition hover:bg-gray-100 text-gray-500 hover:text-gray-900 text-left"
          onClick={() => {
            deletePopup({ templateId: template.id })
          }}
        >
          <i className="fas fa-trash w-4 mr-2" />
          Elimina
        </button>
      </Card>
    </EllipsisMenu>
  )
}

const TemplateCardClassname =
  'bg-white shadow-card h-auto rounded-lg py-2 px-3 cursor-pointer border border-gray-300 hover:border-primary-dark active:border-primary-darker w-full relative pr-7'
// Template o modelli
export default function TemplateList() {
  // Visualize list of stored models
  const [data, { refetch }] = trpc.templates.list.useSuspenseQuery()
  const router = useRouter()

  return (
    <div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mb-4">
        {data.map(template => (
          <div
            className={TemplateCardClassname}
            key={template.id}
            onClick={() => {
              router.push(`/templates/${template.id}`)
            }}
          >
            <h3 className="mb-0 leading-tight">{template.name}</h3>
            {template.description.length > 0 && <p>{template.description}</p>}
            <p className="text-sm mb-0 text-gray-500">
              Creato da {template.createdBy!.nome} {template.createdBy!.cognome} il{' '}
              {moment(template.createdAt).format('LL')}
            </p>
            <div className="absolute right-0 bottom-0">
              <TemplateOptions template={template} refresh={refetch} />
            </div>
          </div>
        ))}
        <div
          className={classNames(
            TemplateCardClassname,
            'flex justify-center items-center text-center align-middle hover:text-primary-dark'
          )}
          onClick={() => {
            // creaPopup()
            router.push(`/templates/new`)
          }}
        >
          <span className="text-lg font-medium">
            <i className="fas fa-plus" /> Crea un nuovo Modello
          </span>
        </div>
      </div>
    </div>
  )
}
