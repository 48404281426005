import UserPath from '@apb/database/UserPath'
import Link from 'next/link'
// import Head from 'next/head'
// import Link from 'next/link'
import { useRouter } from 'next/router'
// import { useState } from 'react'
import { useEffect, useMemo } from 'react'
// import Tilt from 'react-parallax-tilt'

import { useAuthState } from '../atoms/auth'
// import Card from '../components/Card'
import LoadingScreen from '../components/LoadingScreen'
import AziendaView from '../containers/AziendaView'
import AziendeView from '../containers/AziendeView'
import LineManagerView from '../containers/LineManagerView'
import TemplateList from '../containers/TemplateList'
import Header from '../components/Header'

// function DashboardCard({ children, className }: { children: ReactNode; className: string }): JSX.Element {
//   return (
//     <Tilt scale={1.03} tiltMaxAngleX={6} tiltMaxAngleY={12} perspective={1200} tiltReverse>
//       <Card className={className}>{children}</Card>
//     </Tilt>
//   )
// }

// function getLink(path: UserPath, userId: number): string {
//   switch (path.role) {
//     case 'COLLABORATORE':
//       return `/board/${userId}`
//     case 'MANAGER':
//     case 'LINE_MANAGER':
//       return `/lineManager/${userId}`
//     case 'HR':
//     case 'DIREZIONE':
//     case 'DIREZIONE_GENERALE':
//       return `/aziende/${path.aziendaId}`
//     case 'ADMIN':
//       return '/aziende'
//   }
// }

function Dashboard(): JSX.Element {
  const authState = useAuthState()
  const router = useRouter()
  // const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    switch (authState.state) {
      case 'loggedOut':
        router.push('/login')
        break
    }
  }, [authState, router])

  const authPath = useMemo(() => (authState.state === 'loggedIn' ? new UserPath(authState) : null), [authState])

  if (!authPath) return <LoadingScreen />

  switch (authPath.role) {
    case 'ADMIN':
      return (
        <>
          <Link href="/templates">
            <a>
              <h2>Modelli</h2>
            </a>
          </Link>
          <TemplateList />
          <AziendeView />
        </>
      )
    case 'DIREZIONE_GENERALE':
    case 'DIREZIONE':
    case 'HR':
      return <AziendaView aziendaId={authPath.aziendaId!} />
    case 'LINE_MANAGER':
    case 'MANAGER':
    case 'COLLABORATORE':
      return <LineManagerView lineManagerId={authPath.id} />
    default:
      throw new Error('Unsupported role')
  }

  // if (!initialized || authState.state !== 'loggedIn')

  // return (
  //   <div>
  //     <Head>
  //       <title>Home - Agile Personal Board</title>
  //     </Head>
  //     <h1 className="font-light text-gray-700">
  //       Benvenuto/a {authState.user.nome} {authState.user.cognome} a Agile Personal Board
  //     </h1>
  //     <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
  //       {authState.roles.collaboratore && (
  //         <DashboardCard className="bg-green-400 hover:bg-green-500 transition">
  //           <Link href={getLink(authState.roles.collaboratore, authState.user.id)} passHref>
  //             <h4 className="text-white cursor-pointer">
  //               <span className="font-light">Visita la tua </span>Personal Board
  //             </h4>
  //           </Link>
  //         </DashboardCard>
  //       )}

  //       {authState.roles.lineManager && (
  //         <DashboardCard className="bg-blue-400 hover:bg-blue-500 transition">
  //           <Link href={getLink(authState.roles.lineManager, authState.user.id)} passHref>
  //             <h4 className="text-white cursor-pointer">
  //               <span className="font-light">Visita i tuoi </span>Collaboratori
  //             </h4>
  //           </Link>
  //         </DashboardCard>
  //       )}

  //       {authState.roles.hr && (
  //         <DashboardCard className="bg-blue-400 hover:bg-blue-500 transition">
  //           <Link href={getLink(authState.roles.hr, authState.user.id)} passHref>
  //             <h4 className="text-white cursor-pointer">
  //               <span className="font-light">Visita la tua </span>Azienda
  //             </h4>
  //           </Link>
  //         </DashboardCard>
  //       )}

  //       {authState.roles.direzione && (
  //         <DashboardCard className="bg-blue-400 hover:bg-blue-500 transition">
  //           <Link href={getLink(authState.roles.direzione, authState.user.id)} passHref>
  //             <h4 className="text-white cursor-pointer">
  //               <span className="font-light">Visita la tua </span>Azienda
  //             </h4>
  //           </Link>
  //         </DashboardCard>
  //       )}

  //       {authState.roles.admin && (
  //         <DashboardCard className="bg-red-400 hover:bg-red-500 transition">
  //           <Link href={getLink(authState.roles.admin, authState.user.id)} passHref>
  //             <h4 className="text-white cursor-pointer">
  //               <span className="font-light">Visita le </span>Aziende
  //             </h4>
  //           </Link>
  //         </DashboardCard>
  //       )}
  //     </div>
  //   </div>
  // )
}

export default function ProtectedHomePage(): JSX.Element {
  return (
    <div className="page-container-std">
      <Header />
      <Dashboard />
    </div>
  )
}
